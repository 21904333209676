import React, { useEffect, useState } from "react";
import { firestore } from "../../firebase";
import { useSelector, useDispatch } from "react-redux";
import { setInout, addInout } from "../../store/data/actions";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  where,
  getDocs,
  getDoc,
  limit,
  startAfter,
  startAt,
  Timestamp,
} from "firebase/firestore";

import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import Table from "./table";
import { useNavigate } from "react-router-dom";

const InOut = (props) => {
  // INOUT
    // const dispatch = useDispatch();
    
  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="TicketControl"
            breadcrumbItem="Entradas/Salidas"
          />
          <Table />
        </Container>
      </div>
    </React.Fragment>
  );
};

function formatDate(date) {
  const pad = (n) => (n < 10 ? "0" + n : n);
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.0000`;
}

const mapDispatchToProps = (dispatch) => {
  return {
    setInout: (data) => dispatch(setInout(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    currentCompany: state.companies.currentCompany,
    listInout: state.data.inout,
    appConigData: state.data.appConfig,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InOut);
