import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

// redux
import { useSelector, useDispatch } from "react-redux";

//constants
import { layoutTypes } from "../constants/layout";

// layouts
import NonAuthLayout from "../Layout/NonAuthLayout";
import VerticalLayout from "../Layout/VerticalLayout/index";
import HorizontalLayout from "../Layout/HorizontalLayout/index";
import { AuthProtected } from "./AuthProtected";

import { authProtectedRoutes, publicRoutes } from "./routes";
import { collection, addDoc, getDocs, getDoc, doc, onSnapshot, where, query, collectionGroup } from "firebase/firestore";
import { firestore } from '../firebase';
import { setDataCompanies } from '../store/companies/actions'
import ListenerDB from './ListenerDB'
import { LicenseManager } from "ag-grid-enterprise";
//please asign your license key to the licenseKey constant as a string below
const licenseKey =
  "CompanyName=Equinix Asia Pacific pte ltd,LicensedGroup=equinixMendixPrivateLib,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=0,AssetReference=AG-027567,SupportServicesEnd=18_June_2023_[v2]_MTY4NzA0MjgwMDAwMA==4be2c388f9a8a7443c72842dff53d5b2";
LicenseManager.setLicenseKey(licenseKey);
const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const Index = () => {


  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));



  useEffect(() => {

    // const user = JSON.parse(localStorage.getItem('authUser'))
    // if (user && user.email) {
    //   setEmail(user.email)
    // }

  }, [])

  const Layout = getLayout(layoutType);

  return (
    <Routes>

      <Route>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Route>

      <Route>
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <AuthProtected>
                <CheckCompanies email={'email'} >
                  <ListenerDB />
                  <Layout>{route.component}</Layout>
                </CheckCompanies>
              </AuthProtected>}
            key={idx}
            exact={true}
          />
        ))}
      </Route>
    </Routes>
  );
};


const CheckCompanies = (props) => {

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const user = JSON.parse(localStorage.getItem('authUser'))

  useEffect(() => {
    const fetchData = async () => {
      
      try {

        // IF user is admin, get all companies
        if (user.email == 'ticketcontrol.latam_NOVA@gmail.com') {
          const companiesCollectionRef = collection(firestore, "users");
          const companiesSnapshot = await getDocs(companiesCollectionRef);
          const companies = [];
          companiesSnapshot.forEach((doc) => {
            const company = doc.data().company;
            if (company !== undefined) {
            company.id = doc.id;
            companies.push(company);
            }
          });
          
          // console.log('companies', companies)
          dispatch(setDataCompanies(companies));
          const _list = companies.map(company => company.id)
          setList(_list);
          setLoading(false);
        }
        else {
          const promises = [];
          const authCollectionRef = collectionGroup(firestore, "auth");
          const q = query(authCollectionRef, where("email", "==", user.email));

          // //FORZAR CON EMAIL
          // const q = query(
          //   authCollectionRef,
          //   where("email", "==", "naranjo99111@gmail.com")
          // );

          const querySnapshot = await getDocs(q);
          const resultList = [];
          const companies = [];
          querySnapshot.forEach((doc) => {
            const userDocRef = doc.ref.parent.parent; // Referencia al documento "users"
            const result = {
              id: userDocRef.id,
            };
            resultList.push(result.id);
            // Obtener el campo 'company' del documento "users"
            const promise = getDoc(userDocRef).then((userDocSnapshot) => {
              if (userDocSnapshot.exists()) {
                const userData = userDocSnapshot.data();
                let company = userData.company;
                if (company !== undefined) {
                  company = { ...company, id: userDocRef.id };
                  companies.push(company);
                }
              }
            });
            promises.push(promise);
          });

          await Promise.all(promises);
          
          setList(resultList);
          dispatch(setDataCompanies(companies));
          
          setLoading(false);
        }
      } catch (error) {
        console.log(error)
        setLoading(false);
      }
    };

    fetchData();

  }, [])


  if (loading) { return <Loading /> }


  if (list.length === 0) { return <NoCompany /> }

  else {
    return <>{props.children}</>
  }
}

export default Index;



const Loading = () => {

  return (
    <React.Fragment>
      <div className="my-5 pt-5">
        <div className="w-100">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="text-center">

                  <div>
                    <h4 className="text-uppercase mt-4">Cargando...</h4>
                    <p>Estamos recopilando la información</p>

                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

    </React.Fragment>
  )
}

const reloadPage = () => {
  window.location.reload();
};

const NoCompany = () => {

  return (
    <React.Fragment>
      <div className="my-5 pt-5">
        <div className="w-100">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="text-center">
                  <div>
                    <h4 className="text-uppercase mt-4">No hay Estacionamiento asignado a esta cuenta</h4>
                    <p>Para autorizarte, debes ingresar a TicketControl y autorizar tu email. Ingresa en Configuración -> Ajustes -> Monitor.</p>
                    {/* Reload Button */}
                    <button className="btn btn-secondary waves-effect waves-light mt-3 me-2" onClick={reloadPage}>
                      Volver a Cargar
                    </button>
                    {/* Logout Button */}
                    <Link to="/logout" className="btn btn-primary waves-effect waves-light mt-3">
                      Salir
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}