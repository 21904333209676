import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Card, CardBody, CardImg, CardTitle, CardFooter } from "reactstrap";
import styles from "./styles.module.css";
import moment from "moment";
import "moment/locale/es";
import { Modal, ModalBody } from "reactstrap";

function formatLicensePlate(plate) {
  // Expresión regular para separar letras y números
  return plate.replace(
    /([A-Z]+)(\d+)([A-Z]*)/,
    (match, letters1, numbers, letters2) => {
      const parts = [letters1, numbers, letters2].filter(Boolean); // Elimina partes vacías
      return parts.join(" "); // Une las partes con un espacio
    }
  );
}

//  const TimeAgo = ({ date }) => {
//    moment.locale("es");
//    const timeAgo = moment(date).fromNow();
//     const time = moment(date).format("DD/MM HH:mm");
//    return <span>{time} - {timeAgo}</span>;
// };
const TimeAgo = ({ date }) => {
  moment.locale("es");
  // const timeAgo = moment(date).fromNow();
  const timeAgo = moment(date).format("DD/MM HH:mm");

  return <span>{timeAgo}</span>;
};

const ItemAnpr = (props) => {
  const item = props.ticket;

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [modeImage, setModeImage] = useState(2);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  const getType = (type) => {
    switch (type) {
      case "hour":
        return "xHora";
      case "stay":
        return "xEstadia";
      case "tn":
        return "xTurno";
      default:
        return "--";
    }
  };

  const getStatusText = (item) => {
    if (item.canceled === 1) {
      return "Cancelado";
    } else if (item.closed === 1) {
      return "Cerrado";
    } else {
      return "Abierto";
    }
  };

  return (
    <>
      <Card className={`${styles.card} mb-4 shadow-lg`}>
        <div className={styles["image-container"]}>
          <CardImg
            src={`data:image/png;base64,${item.picture}`}
            alt={`Imagen del vehículo con matrícula ${item.plate}`}
            className={styles["card-img"]}
            onClick={() => openModal(`data:image/png;base64,${item.picture}`)}
            cssModule={{ "img-fluid": true }}
          />
          {/* Indicador flotante */}
          <div
            className={`${styles["status-indicator"]} ${
              item.canceled === 1
                ? styles["status-canceled"]
                : item.closed === 0
                ? styles["status-open"]
                : styles["status-closed"]
            }`}
          >
            {getStatusText(item)}
          </div>
        </div>
        <CardBody className={`${styles["card-body"]}`}>
          <CardTitle tag="h3" className={styles["cardTitle"]}>
            <strong>{formatLicensePlate(item.plate)}</strong>
          </CardTitle>

          <div className={styles["card-category"]}>{item.category_name}</div>
          <div className={styles["card-type"]}>{getType(item.type)}</div>
          <div className={styles["card-car-details"]}>{item.car_detail}</div>
          {item.canceled == 1 && (
            <div className={"text-center " + styles["card-canceled-text"]}>
              Cancelado
            </div>
          )}
          {/* {JSON.stringify(item)} */}
        </CardBody>
        {/* FOOTER */}

        {/* Footer del Card */}
        <CardFooter className={styles["card-footer"]}>
          <TimeAgo date={item.init_date} />
          <div>
            <small>Abierto por {item.fullName.toUpperCase()}</small>
          </div>
          {item.closed == 1 && (
            <div>
              <small>Cerrado por {item.closed_by_fullName.toUpperCase()}</small>
            </div>
          )}
          {/* {JSON.stringify(item)} */}
          {item.canceled_details && (
            <div className={styles["canceled-details"]}>
              <small>Motivo de la canelación: {item.canceled_details}</small>
            </div>
          )}
        </CardFooter>
      </Card>
      <Modal
        isOpen={modalOpen}
        toggle={closeModal}
        size="lg"
        centered
        keyboard={true}
        backdrop={true}
      >
        <ModalBody
          className="d-flex justify-content-center align-items-center"
          style={{ padding: 0 }}
        >
          {selectedImage && (
            <img
              src={selectedImage}
              alt="ticket full-size"
              style={{
                objectFit: "contain",
                maxWidth: "100%",
                maxHeight: "60vh",
                width: "auto",
                height: "auto",
                zoom: 1.5,
              }}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default ItemAnpr;
